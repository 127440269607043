<template>
  <div>
    <div class="hire-us-details-content-div row">
      <div class="col-md-6">
        <h4 class="category-title">
          Pick a Category
        </h4>
      </div>
      <div v-if="showBuckets" class="hire-us-search-div col-md-6">
        <product-search />
      </div>
    </div>

    <b-list-group class="w-100 pointer product-categories-list">
      <div v-if="showBuckets">
        <selection-cards
          :items="formattedProductBuckets"
          @chosen="route"
        />
      </div>
      <ct-centered-spinner v-else class="mt-5" />
    </b-list-group>

    <formation-info-modal
      ref="formation-info-modal"
      v-model="companyAdded"
      :company="company"
      :load-products="false"
      @formation-info-modal-complete="formationInfoModalCompleteHandler"
    />
    <company-domestic-registration-modal
      ref="company-domestic-registration-modal"
      :company="company"
      :load-products="false"
      @domestic-registration-changed="domesticRegistrationChanged"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'ChooseBucket',
    components: {
      CompanyDomesticRegistrationModal: () => import('@/components/CompanyDomesticRegistrationModal'),
      CtCenteredSpinner:                () => import('@/components/shared/CtCenteredSpinner'),
      FormationInfoModal:               () => import('@/components/StagelineV2/modals/FormationInfoModal'),
      ProductSearch:                    () => import('@/components/HireUs/ProductSearch'),
      SelectionCards:                   () => import('@/components/shared/SelectionCards'),
    },
    props: {
      companyId: String,
    },
    data() {
      return {
        isLoaded: false,
        phoneTrial: false,
        companyAdded: false,
        bucket: '',
        excludedIfExists: {}, // If these services are marked true, they are removed from being displayed
      }
    },
    computed: {
      ...mapGetters('products', ['productBuckets']),
      ...mapGetters('dashpanel',
        ['activeLawOnCallServices']
      ),
      ...mapGetters('checkout', [
        'productsLoaded',
      ]),
      ...mapGetters('companies', {
        activeRAServices: 'activeRAServices',
        company: 'currentCompany',
      }),
      ...mapGetters('companies', [
        'domesticRegistration',
        'noJurisdictionForCompany',
        'formedElsewhere',
        'formationInfoObtained',
        'activeServices',
        'suspendedServices',
      ]),
      ...mapGetters('jurisdictions', [
        'jurisdictions',
      ]),
      ...mapGetters('monitoring', [
        'monitoringTrialEligible',
      ]),
      showBuckets() {
        return this.formattedProductBuckets.length && this.isLoaded
      },
      formattedProductBuckets() {
        let formattedList = this.productBuckets

        return formattedList
          .sort((a, b) => a.sorting_order - b.sorting_order)
          .filter(category => {
            return !(this.excludedIfExists[category.name] !== undefined && this.excludedIfExists[category.name] === false)
          }).map(category => {
            return {
              name: category.name,
              bucketId: category.id,
              route: 'choose-product-category',
              description: category.description,
            }
          })
      },
    },
    async mounted() {
      this.$emit('toggleButtonVisibility', { backButtonHidden: true, addContactButtonHidden: true })
      if (!this.jurisdictions.length) {
        await this.loadJurisdictions
      }

      await this.determineTrialEligibilityMonitoring(this.companyId)
      await this.getProductBuckets(this.companyId)
      await this.loadActiveServices({ id: this.companyId })
      await this.loadSuspendedServices({ id: this.companyId })

      this.excludedIfExists = { 'Monitoring': this.monitoringTrialEligible }

      this.$root.$emit('log-interaction', { type: 'view', name: 'view-buckets' })
      this.isLoaded = true
    },
    methods: {
      ...mapActions('products', ['getProductBuckets']),
      ...mapActions('companies', ['loadActiveServices', 'loadSuspendedServices']),
      ...mapActions('jurisdictions', { loadJurisdictions: 'load' }),
      ...mapActions('monitoring', [
        'determineTrialEligibilityMonitoring',
      ]),
      async route(bucket=null) {
        if (bucket) {
          this.$root.$emit('log-interaction', { type: 'button-redirect', name: 'bucket-selected', subName: bucket.name })
          this.bucket = bucket
        }
        if (await this.verifyRequiredCompanyInfo()) {
          await this.$router.push({
            name: bucket.route,
            params: { companyId: this.companyId, bucketId: bucket.bucketId, categoryName: bucket.name },
          })
        }
      },
      // Made async to allow using return value in route check
      async verifyRequiredCompanyInfo() {
        return new Promise((resolve, _reject) => {
          if (!this.formationInfoObtained && [null, undefined].includes(this.company?.config['formed_elsewhere'])) {
            this.presentFormationInfoModal()
            resolve(false)
          } else if (this.noJurisdictionForCompany) {
            this.presentCompanyDomesticRegistrationModal()
            resolve(false)
          } else {
            resolve(true)
          }
        })
      },
      presentFormationInfoModal() {
        this.$refs['formation-info-modal'].show()
      },
      presentCompanyDomesticRegistrationModal() {
        this.$refs['company-domestic-registration-modal'].show()
      },
      async formationInfoModalCompleteHandler() {
        await this.route(this.productKind)
      },
      async domesticRegistrationChanged() {
        await this.route(this.productKind)
      },
    },
  }
</script>

<style lang="scss">

.category-title {
  text-align: left;
}

.product-categories-list {
  padding-top: 1rem;
}

.hire-us-details-content-div {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 0;
}
.hire-us-search-div {
  margin-top: 1.33em;
  width: 50%;
}

</style>
