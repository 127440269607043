var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "hire-us-details-content-div row" }, [
        _vm._m(0),
        _vm.showBuckets
          ? _c(
              "div",
              { staticClass: "hire-us-search-div col-md-6" },
              [_c("product-search")],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-list-group",
        { staticClass: "w-100 pointer product-categories-list" },
        [
          _vm.showBuckets
            ? _c(
                "div",
                [
                  _c("selection-cards", {
                    attrs: { items: _vm.formattedProductBuckets },
                    on: { chosen: _vm.route },
                  }),
                ],
                1
              )
            : _c("ct-centered-spinner", { staticClass: "mt-5" }),
        ],
        1
      ),
      _c("formation-info-modal", {
        ref: "formation-info-modal",
        attrs: { company: _vm.company, "load-products": false },
        on: {
          "formation-info-modal-complete":
            _vm.formationInfoModalCompleteHandler,
        },
        model: {
          value: _vm.companyAdded,
          callback: function ($$v) {
            _vm.companyAdded = $$v
          },
          expression: "companyAdded",
        },
      }),
      _c("company-domestic-registration-modal", {
        ref: "company-domestic-registration-modal",
        attrs: { company: _vm.company, "load-products": false },
        on: {
          "domestic-registration-changed": _vm.domesticRegistrationChanged,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h4", { staticClass: "category-title" }, [
        _vm._v("\n        Pick a Category\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }